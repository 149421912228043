import React from "react"
import ReactDOM from "react-dom"
// import reportWebVitals from "./reportWebVitals"
import StyleContext from "isomorphic-style-loader/StyleContext"

// eslint-disable-next-line no-undef
__webpack_public_path__ = process.env.REACT_APP_PUBLIC_URL

const insertCss = (...styles) => {
  const removeCss = styles.map(style => style)
  return () => removeCss.forEach(dispose => dispose())
}

window.__render_component = function(id, componentId, props) {
  return import(`./${componentId}`).then(({ default: Component }) => {
    ReactDOM.hydrate(
      <StyleContext.Provider value={{ insertCss }}>
        <Component {...props} />
      </StyleContext.Provider>,
      document.getElementById(id)
    )
  })
}

window.dispatchEvent(new Event("RenderComponentDeclared"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
